import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import axios from 'axios';
import api from '../../../services/API';
import InputMask from 'react-input-mask';
import validarCpf from '../../../utils/validarCpf';
import historico from '../../../services/Historico';
import Header from '../../../components/Loja/Header/index';
import Footer from '../../../components/Loja/Footer/index';
import SelectCidades from '../../../components/Painel/SelectCidades/index';
import GoogleLogin from '../../../components/Loja/GoogleLogin';
import ModalRegulamento from '../../../components/Loja/ModalRegulamento/index';
import { useLocation } from 'react-router-dom';
import FacebookLogin from 'react-facebook-login';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { autenticarParceiroSocial } from '../../../store/modules/autenticacao/actions';
import { useDispatch } from 'react-redux';

export default function Cadastrar() {
  const [enviando, setEnviando] = useState(false);
  const [cpf, setCpf] = useState('');
  const [nome, setNome] = useState('');
  const [dataNascimento, setDataNascimento] = useState('');
  const [email, setEmail] = useState('');
  const [celular, setCelular] = useState('');
  const [senha, setSenha] = useState('');
  const [confirmarSenha, setConfirmarSenha] = useState('');
  const [lojaPreferida, setLojaPreferida] = useState('');
  const [concordaTermos, setConcordaTermos] = useState(0);
  const [concordaRegulamento, setConcordaRegulamento] = useState(0);
  const [revendas, setRevendas] = useState([]);
  const [modalRegulamento, setModalRegulamento] = useState(false);
  const [indicacao, setIndicacao] = useState('');
  const [indicacaoVendedor, setIndicacaoVendedor] = useState('');
  const query = new URLSearchParams(useLocation().search);
  const dispatch = useDispatch();

  const validarCampos = (data) => {
    const entries = Object.entries(data);
    let temNulo = false;
    entries.forEach((param) => {
      if (temNulo) return;
      if ((param[1] === '' || param[1] == 0) && param[0] === 'endCidade') {
        toast.error(`Cidade obrigatório`, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        });
        temNulo = true;
        return;
      }
      if ((param[1] === '' || param[1] == 0) && param[0] === 'concordaTermos') {
        toast.error(
          `Você deve concordar com termo de compartilhamento de dados antes de prosseguir`,
          {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 10000,
          }
        );
        temNulo = true;
        return;
      }
      if (
        (param[1] === '' || param[1] == 0) &&
        param[0] === 'aceitouRegulamento'
      ) {
        toast.error(
          `Você deve concordar com os termos do regulamento antes de prosseguir`,
          {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 10000,
          }
        );
        temNulo = true;
        return;
      }
      if (param[1] === '' && param[0] === 'sexo') {
        toast.error(`Sexo obrigatório`, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        });
        temNulo = true;
        return;
      }
      if (
        param[1] === '' &&
        param[0] !== 'endComplemento' &&
        param[0] !== 'telefone' &&
        param[0] !== 'lojaPreferida' &&
        param[0] !== 'cidadeLojaPreferida' &&
        param[0] !== 'estadoLojaPreferida' &&
        param[0] !== 'indicacaoLoja' &&
        param[0] !== 'indicacaoVendedor'
      ) {
        const elemento = document.getElementsByName(param[0])[0];
        toast.error(`${elemento.placeholder} obrigatório`, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        });
        temNulo = true;
        return;
      }
    });

    return temNulo;
  };

  const buscarCpf = async (cpf) => {
    cpf = cpf.replace(/[^\d]/g, '');
    if (!cpf) return;
    const filtros = {
      cpf,
    };
    const resultado = await api.get(
      `/api/parceiros?filtros=${JSON.stringify(filtros)}`
    );
    if (resultado.data.parceiros.length > 0) {
      toast.error('Pintor já cadastrado', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      setCpf('');
    }
  };

  const validarBuscarCpf = (e) => {
    const cpf = e.target.value;
    if (!cpf) return;
    if (!validarCpf(cpf)) {
      toast.error('CPF não é válido', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      setCpf('');
    } else {
      buscarCpf(cpf);
    }
  };

  const validarTelefoneCelular = async (e) => {
    const valor = e.target.value.replace(/[^\d]/g, '');
    if (!valor) return;
    if (valor.length < 10) {
      toast.error(`${e.target.placeholder} inválido`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      if (e.target.placeholder === 'Telefone') setCelular('');
    }
  };

  const validaIndicacao = async (e) => {
    const Indicacao = e.target.value;
    if (Indicacao) {
      const resultado = await api.post(`/api/indique-ganhe/validate`, {
        indicacao: Indicacao,
      });

      // console.log(resultado);

      if (resultado.data.indicador) {
        // console.log('Indicador OK!');
      } else {
        // console.log('Indicador BAD!');
        setIndicacao('');
        toast.error('Indicador não encontrado', {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        });
        return;
      }
    }
  };

  const validarDataNascimento = async (e) => {
    const data = e.target.value.replace(/[^\d]/g, '');

    //Analisando se existe alguma data inserida
    if (!data) return;
    if (data.length != 8) {
      toast.error(`${e.target.placeholder} inválida`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      setDataNascimento('');
      return;
    } else {
      const day = Number(data.substr(0, 2));
      const month = Number(data.substr(2, 2));
      const year = Number(data.substr(4, 4));
      const dataAtual = new Date();
      let idade = dataAtual.getFullYear() - year;

      // console.log(day, month, year, idade);

      if (
        dataAtual.getMonth() + 1 < month ||
        (dataAtual.getMonth() + 1 == month && dataAtual.getDate() < day)
      ) {
        idade--;
      }
      // console.log(day, month, year, idade);

      //Analisando datas inválidas
      const dataUsuario = new Date(year, Number(month) - 1, day);
      if (dataUsuario.valueOf() > dataAtual.valueOf()) {
        toast.error(`${e.target.placeholder} inválida`, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        });
        setDataNascimento('');
        return;
      }

      //Analisando se usuário tem menos de 18 anos
      if (idade < 18) {
        toast.error(
          `Você precisa ter 18 anos ou mais para participar do Clube do Pintor`,
          {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 10000,
          }
        );
        setDataNascimento('');
        return;
      }
    }
  };


  const cadastrarParceiro = async (e) => {
    try {
      e.preventDefault();
      setEnviando(true);
      
      const data = {
        nome,
        cpf,
        celular,
        email,
        dataNascimento,
        senha,
        confirmarSenha,
        lojaPreferida,
        concordaTermos,
        aceitouRegulamento: concordaRegulamento,
        indicacaoVendedor,
        facebookId: query.get('facebookId') || null,
        googleAuthId: query.get('googleAuthId') || null,
        codigo_cadastro: query.get('codigo_cadastro'),
        telefone: '',
        sexo: 'O',
        endRua: 'Não informado',
        endNumero: '0',
        endComplemento: '',
        endBairro: 'Não informado',
        endCidade: 'Não informado',
        endEstado: 'SP',
        endCep: '00000000',
        cidadeLojaPreferida: '',
        estadoLojaPreferida: '',
        indicacaoLoja: null
      };

      await validarEmail();
      const temNulo = validarCampos(data);
      if (temNulo) {
        setEnviando(false);
        return;
      }

      data.indicacao = indicacao;
      
      data.endCep = data.endCep.replace(/[^\d]/g, '');
      data.telefone = data.telefone.replace(/[^\d]/g, '');
      data.celular = data.celular.replace(/[^\d]/g, '');
      data.cpf = data.cpf.replace(/[^\d]/g, '');
      data.dataNascimento = data.dataNascimento.replace(/[^\d]/g, '');
      data.dataNascimento = `${data.dataNascimento.substring(4)}${data.dataNascimento.substring(2, 4)}${data.dataNascimento.substring(0, 2)}`;

      delete data.aceitouRegulamento;
      delete data.confirmarSenha;

      const resultado = await api.post('/api/parceiros', data);

      if (resultado?.data) {
        toast.success('Cadastro realizado com sucesso. Faça login com seu CPF e senha.', {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        });
        historico.push('/pos-cadastro');
      }
    } catch (error) {
      toast.error(error?.response?.data?.erro || 'Erro ao realizar cadastro. Tente novamente.', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
    } finally {
      setEnviando(false);
    }
  };

  const validarEmail = async () => {
    if (email && !email.match(/^[a-z0-9.]+@[a-z0-9]+\.[a-z]+(\.[a-z]+)?$/i)) {
      setEmail('');
      toast.error(`Email inválido`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }
  };

  const validarCaractereCpf = (e) => {
    const theEvent = e;
    let key = null;
    if (theEvent.type === 'paste') {
      key = window.event.clipboardData.getData('text/plain');
    } else {
      key = theEvent.keyCode || theEvent.which;
      key = String.fromCharCode(key);
    }
    const regex = /[0-9]|\./;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  };

  const buscarRevendas = async () => {
    const resultado = await api.get('/api/revendas');
    const dataRevendas = resultado.data.revendas.sort((a, b) => {
      if (a.nomeInput > b.nomeInput) return 1;
      if (a.nomeInput < b.nomeInput) return -1;
      return 0;
    });

    setRevendas(dataRevendas);
  };


  const responseFacebook = (e) => {
    console.log(`responseFacebook`, e);
    dispatch(autenticarParceiroSocial('facebookToken', e.accessToken));
  }

  const responseGoogle = (e) => {
    console.log(`googleAuthentication`, e, {index: 'googleOauth',social_token: e.credential});
    dispatch(autenticarParceiroSocial('googleOauth',e.credential));
  }

  const responseGoogleError = (e) => {
    console.log(`googleAuthentication`, e);
  }

  useEffect(() => {
    document.title = 'Clube Pro Pintor - Cadastrar';
    buscarRevendas();
    setNome(query.get('name') || null)
    setEmail(query.get('email') || null)
  }, []);

  return (
    <>
      <Header auto />
      <Container className="d-flex flex-column justify-content-center">
        {/*<Row className="justify-content-center mt-5">*/}
        {/*  <img*/}
        {/*    className="w-100 form-logo"*/}
        {/*    src="/imagens/logo-fundo-claro.png"*/}
        {/*    alt="Logo Clube Pro Pintor"*/}
        {/*  ></img>*/}
        {/*</Row>*/}

        <Row className="d-flex justify-content-center mt-5 mb-5">
          <Col xs={12} className="d-flex justify-content-center">
            <form className="form" onSubmit={cadastrarParceiro}>

            <div style={{paddingBottom: '14px'}}>
              <h5>FAÇA SEU CADASTRO</h5>
              <label>Insira seus dados para criar sua conta</label>
            </div>
      {/* Facebook Login Button
            <FacebookLogin
              appId="571176977891327"
              fields="name,email,picture"
              icon="fa-facebook"
              textButton=" Login com Facebook"
              cssClass="btn-primario btn-primario2"
              callback={responseFacebook}
            />
            */}

            {/* Google Login Button 
            <div id="googlebtn" style={{paddingTop: '16px', width: '100%'}}>
              <GoogleOAuthProvider clientId="425931863053-i3tf8n6mrujvl5slcs7pr4ql48po0eg8.apps.googleusercontent.com">
                <GoogleLogin></GoogleLogin>
              </GoogleOAuthProvider>
            </div>

            <p style={{paddingBottom: '16px', paddingTop: '16px', marginBottom: '0', textAlign: 'center'}}>ou</p>
            */}

              <Row>
                <Col className="d-flex flex-column" xs={12}>
                  <label htmlFor="nome">Nome Completo *</label>
                  <input
                    id="nome"
                    name="nome"
                    type="text"
                    placeholder="Nome Completo"
                    value={nome}
                    onChange={(e) => setNome(e.target.value)}
                  ></input>
                </Col>
              </Row>
              <Row>
                <Col className="d-flex flex-column" xs={12} sm={6}>
                  <label htmlFor="cpf">CPF *</label>
                  <InputMask
                    id="cpf"
                    name="cpf"
                    type="text"
                    placeholder="CPF"
                    value={cpf}
                    mask="999.999.999-99"
                    onChange={(e) => setCpf(e.target.value)}
                    onBlur={validarBuscarCpf}
                    onKeyPress={validarCaractereCpf}
                  ></InputMask>
                </Col>
                <Col className="d-flex flex-column" xs={12} sm={6}>
                  <label htmlFor="dataNascimento">Data de Nascimento *</label>
                  <InputMask
                    id="dataNascimento"
                    name="dataNascimento"
                    type="text"
                    placeholder="Data de Nascimento"
                    value={dataNascimento}
                    onBlur={validarDataNascimento}
                    onChange={(e) => setDataNascimento(e.target.value)}
                    mask="99/99/9999"
                  ></InputMask>
                </Col>
              </Row>

              <label htmlFor="email">Email *</label>
              <input
                id="email"
                name="email"
                type="text"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                autoComplete="email"
              ></input>

              <Row>
                <Col className="d-flex flex-column" xs={12}>
                  <label htmlFor="celular">Celular *</label>
                  <InputMask
                    id="celular"
                    name="celular"
                    type="text"
                    placeholder="Celular"
                    value={celular}
                    mask="(99) 99999-9999"
                    onChange={(e) => setCelular(e.target.value)}
                    onBlur={validarTelefoneCelular}
                  ></InputMask>
                </Col>
                {/* <Col className="d-flex flex-column" xs={12} sm={6}>
                  <label htmlFor="telefone">Telefone</label>
                  <InputMask
                    id="telefone"
                    name="telefone"
                    type="text"
                    placeholder="Telefone"
                    value={telefone}
                    mask="(99) 9999-9999"
                    onChange={(e) => setTelefone(e.target.value)}
                    onBlur={validarTelefoneCelular}
                  ></InputMask>
                </Col>
              </Row>
              <Row>
                <Col className="d-flex flex-column" xs={12} sm={6}>
                  <label htmlFor="sexo">Genêro *</label>
                  <select
                    id="sexo"
                    name="sexo"
                    value={sexo}
                    onChange={(e) => setSexo(e.target.value)}
                    placeholder="Genêro"
                  >
                    <option disabled value="">
                      Selecione o sexo
                    </option>
                    <option value="F">Feminino</option>
                    <option value="M">Masculino</option>
                    <option value="O">Outro</option>
                  </select>
                </Col>
                <Col className="d-flex flex-column" xs={12} sm={6}>
                  <label htmlFor="endCep">CEP *</label>
                  <InputMask
                    id="endCep"
                    name="endCep"
                    type="text"
                    placeholder="CEP"
                    mask="99999-999"
                    onBlur={buscarCep}
                    value={endCep}
                    onChange={(e) => setCep(e.target.value)}
                  ></InputMask>
                </Col>
              </Row>
              <Row>
                <Col className="d-flex flex-column" xs={12} sm={9}>
                  <label htmlFor="endRua">Endereço *</label>
                  <input
                    id="endRua"
                    name="endRua"
                    type="text"
                    placeholder="Endereço"
                    value={endRua}
                    onChange={(e) => setRua(e.target.value)}
                  ></input>
                </Col>

                <Col className="d-flex flex-column" xs={12} sm={3}>
                  <label htmlFor="endNumero">Número *</label>
                  <input
                    id="endNumero"
                    name="endNumero"
                    type="text"
                    placeholder="Número"
                    value={endNumero}
                    onChange={(e) => setNumero(e.target.value)}
                  ></input>
                </Col>
              </Row>
              <Row>
                <Col className="d-flex flex-column" xs={12} sm={6}>
                  <label htmlFor="endBairro">Bairro *</label>
                  <input
                    id="endBairro"
                    name="endBairro"
                    type="text"
                    placeholder="Bairro"
                    value={endBairro}
                    onChange={(e) => setBairro(e.target.value)}
                  ></input>
                </Col>
                <Col className="d-flex flex-column" xs={12} sm={6}>
                  <label htmlFor="endComplemento">Complemento</label>
                  <input
                    id="endComplemento"
                    name="endComplemento"
                    type="text"
                    placeholder="Complemento"
                    value={endComplemento}
                    onChange={(e) => setComplemento(e.target.value)}
                  ></input>
                </Col>
              </Row>
              <Row>
                <Col className="d-flex flex-column" xs={12} sm={6}>
                  <label htmlFor="endEstado">Estado *</label>
                  <select
                    id="endEstado"
                    name="endEstado"
                    type="text"
                    placeholder="Estado"
                    value={endEstado}
                    onChange={(e) => setEstado(e.target.value)}
                  >
                    <option disabled value="">
                      Estado
                    </option>
                    <option value="AC">Acre</option>
                    <option value="AL">Alagoas</option>
                    <option value="AP">Amapá</option>
                    <option value="AM">Amazonas</option>
                    <option value="BA">Bahia</option>
                    <option value="CE">Ceará</option>
                    <option value="DF">Distrito Federal</option>
                    <option value="ES">Espírito Santo</option>
                    <option value="GO">Goiás</option>
                    <option value="MA">Maranhão</option>
                    <option value="MT">Mato Grosso</option>
                    <option value="MS">Mato Grosso do Sul</option>
                    <option value="MG">Minas Gerais</option>
                    <option value="PA">Pará</option>
                    <option value="PB">Paraíba</option>
                    <option value="PR">Paraná</option>
                    <option value="PE">Pernambuco</option>
                    <option value="PI">Piauí</option>
                    <option value="RJ">Rio de Janeiro</option>
                    <option value="RN">Rio Grande do Norte</option>
                    <option value="RS">Rio Grande do Sul</option>
                    <option value="RO">Rondônia</option>
                    <option value="RR">Roraima</option>
                    <option value="SC">Santa Catarina</option>
                    <option value="SP">São Paulo</option>
                    <option value="SE">Sergipe</option>
                    <option value="TO">Tocantins</option>
                  </select>
                </Col>
                <Col className="d-flex flex-column" xs={12} sm={6}>
                  <label htmlFor="endCidade">Cidade *</label>
                  <SelectCidades
                    endEstado={endEstado}
                    onChange={setCidade}
                    valueChanged={endCidade}
                  />
                </Col> */}
              </Row>
              <Row>
                <Col className="d-flex flex-column" xs={12} sm={6}>
                  <label htmlFor="senha">Senha *</label>
                  <input
                    id="senha"
                    name="senha"
                    type="password"
                    placeholder="Senha"
                    value={senha}
                    onChange={(e) => setSenha(e.target.value)}
                  ></input>
                </Col>
                <Col className="d-flex flex-column" xs={12} sm={6}>
                  <label htmlFor="confirmarSenha">Confirmar Senha *</label>
                  <input
                    id="confirmarSenha"
                    name="confirmarSenha"
                    type="password"
                    placeholder="Confirmar Senha"
                    value={confirmarSenha}
                    onChange={(e) => setConfirmarSenha(e.target.value)}
                  ></input>
                </Col>
              </Row>
              <Row className="mt-1">
                <Col className="d-flex flex-column" xs={12}>
                  <label htmlFor="indicacao">
                    Você foi indicado por algum Pintor?
                  </label>
                  <input
                    id="indicacao"
                    name="indicacao"
                    type="text"
                    placeholder="Código de Indicação"
                    value={indicacao}
                    onBlur={validaIndicacao}
                    onChange={(e) => setIndicacao(e.target.value)}
                  ></input>
                </Col>
              </Row>
              <Row className="mt-1">
                <Col className="d-flex flex-column" xs={12}>
                  <label htmlFor="indicacao">
                    Você foi indicado por algum vendedor?
                  </label>
                  <input
                    id="indicacaoVendedor"
                    name="indicacaoVendedor"
                    type="text"
                    placeholder="Código do Vendedor"
                    value={indicacaoVendedor}
                    onChange={(e) => setIndicacaoVendedor(e.target.value)}
                  ></input>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col className="d-flex flex-column" xs={12}>
                  <label htmlFor="lojaPreferida">
                    Qual loja você mais compra?
                  </label>
                  <input
                    id="lojaPreferida"
                    name="lojaPreferida"
                    type="text"
                    placeholder="Qual loja você mais compra?"
                    value={lojaPreferida}
                    onChange={(e) => setLojaPreferida(e.target.value)}
                  ></input>
                </Col>
              </Row>
              <Row>
                {/* <Col className="d-flex flex-column" xs={12} sm={6}>
                  <label htmlFor="estadoLojaPreferida">Estado da Loja</label>
                  <select
                    id="estadoLojaPreferida"
                    name="estadoLojaPreferida"
                    type="text"
                    placeholder="Estado da Loja"
                    value={estadoLojaPreferida}
                    onChange={(e) => setEstadoLojaPreferida(e.target.value)}
                  >
                    <option disabled value="">
                      Estado da Loja
                    </option>
                    <option value="AC">Acre</option>
                    <option value="AL">Alagoas</option>
                    <option value="AP">Amapá</option>
                    <option value="AM">Amazonas</option>
                    <option value="BA">Bahia</option>
                    <option value="CE">Ceará</option>
                    <option value="DF">Distrito Federal</option>
                    <option value="ES">Espírito Santo</option>
                    <option value="GO">Goiás</option>
                    <option value="MA">Maranhão</option>
                    <option value="MT">Mato Grosso</option>
                    <option value="MS">Mato Grosso do Sul</option>
                    <option value="MG">Minas Gerais</option>
                    <option value="PA">Pará</option>
                    <option value="PB">Paraíba</option>
                    <option value="PR">Paraná</option>
                    <option value="PE">Pernambuco</option>
                    <option value="PI">Piauí</option>
                    <option value="RJ">Rio de Janeiro</option>
                    <option value="RN">Rio Grande do Norte</option>
                    <option value="RS">Rio Grande do Sul</option>
                    <option value="RO">Rondônia</option>
                    <option value="RR">Roraima</option>
                    <option value="SC">Santa Catarina</option>
                    <option value="SP">São Paulo</option>
                    <option value="SE">Sergipe</option>
                    <option value="TO">Tocantins</option>
                  </select>
                </Col>
                <Col className="d-flex flex-column" xs={12} sm={6}>
                  <label htmlFor="cidadeLojaPreferida">Cidade da Loja</label>
                  <SelectCidades
                    endEstado={estadoLojaPreferida}
                    onChange={setCidadeLojaPreferida}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="d-flex flex-column" xs={12}>
                  <label htmlFor="indicacaoLoja">
                    Você foi indicado por alguma loja Sherwin-Williams?
                  </label>
                  <select
                    id="indicacaoLoja"
                    name="indicacaoLoja"
                    type="text"
                    placeholder="Estado"
                    value={indicacaoLoja}
                    onChange={(e) => setIndicacaoLoja(e.target.value)}
                  >
                    <option disabled value="">
                      Selecione
                    </option>
                    {revendas.map((l, i) => (
                      <option value={l.nomeInput} key={l.nomeInput}>
                        {l.nomeInput}
                      </option>
                    ))}
                    <option value=""> </option>
                  </select>
                </Col> */}
              </Row>
              <Row>
                <Col
                  className="d-flex justify-content-center align-items-center mb-3"
                  xs={12}
                >
                  <input
                    className="m-0 f-14"
                    type="checkbox"
                    id="concordaTermos"
                    name="concordaTermos"
                    onClick={(e) =>
                      e.target.checked
                        ? setConcordaTermos(1)
                        : setConcordaTermos(0)
                    }
                  />
                  <label
                    className="m-0 ml-3 f-14 text-justify"
                    htmlFor="concordaTermos"
                  >
                    Concordo em compartilhar meus dados para uso exclusivo da
                    Smart Tecnologia em Vendas S.A. sendo de direito da Smart a
                    transferência destas informações para uso da
                    Sherwin-Williams e empresas afiliadas.
                  </label>
                </Col>
              </Row>
              <Row>
                <Col
                  className="d-flex justify-content-center align-items-center mb-3"
                  xs={12}
                >
                  <input
                    className="m-0 f-14"
                    type="checkbox"
                    id="concordaRegulamento"
                    name="concordaRegulamento"
                    onClick={(e) =>
                      e.target.checked
                        ? setConcordaRegulamento(1)
                        : setConcordaRegulamento(0)
                    }
                  />
                  <label
                    className="m-0 ml-3 f-14 text-justify"
                    htmlFor="concordaRegulamento"
                  >
                    Concordo com os termos deste regulamento.
                    <button
                      className="btn-limpo font-weight-bold"
                      onClick={(e) => {
                        e.preventDefault();
                        setModalRegulamento(true);
                      }}
                    >
                      Consultar Regulamento
                    </button>
                  </label>
                </Col>
              </Row>

              <button className="btn-primario" type="submit">
                {enviando ? `CADASTRANDO...` : `CADASTRAR`}
              </button>
            </form>
          </Col>
        </Row>
      </Container>
      <Footer />
      <ModalRegulamento
        onlyView
        show={modalRegulamento}
        onHide={() => setModalRegulamento(false)}
      />
    </>
  );
}
