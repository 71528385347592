import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Container, Modal, Form, Button } from 'react-bootstrap';
import api from '../../../services/API';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import sortFunc from '../../../utils/ordernarColunaInt';
const ToSearch = (props) => {
  let input;
  const handleClick = () => {
    props.onSearch(input.value);
  };

  const handleEnterKey = (e) => {
    if (e.key === 'Enter') {
      props.onSearch(input.value);
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center">
      <input
        className="input-theme busca mr-1"
        ref={(n) => (input = n)}
        type="text"
        onKeyUp={handleEnterKey}
      />
      <button
        className="btn-primario-slim"
        onClick={handleClick}
        style={{ width: 40, height: 40 }}
      >
        <i className="fa fa-search px-2"></i>
      </button>
    </div>
  );
};

export default function ProdutosSW() {
  const [buscando, setBuscando] = useState(false);
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [editingProduct, setEditingProduct] = useState({
    id: null,
    codigo: '',
    nome: '',
    valorUnitario: '',
    valorPontos: ''
  });

  const buscarProdutos = async () => {
    setBuscando(true);
    try {
      const resultado = await api.get('/api/produtos');
      if (resultado) {
        setData(resultado.data.produtos);
        console.log('Dados recebidos:', resultado.data.produtos); 
      }
    } catch (error) {
      console.error('Erro ao buscar produtos:', error);
    }
    setBuscando(false);
  };

  useEffect(() => {
    buscarProdutos();
  }, []);

  const handleEdit = async (row) => {
    try {
      setEditingProduct({
        id: row.id,
        codigo: row.codigo,
        nome: row.nome,
        valorUnitario: row.valorUnitario,
        valorPontos: row.valorPontos
      });
      setShowModal(true);
    } catch (error) {
      console.error('Erro ao carregar produto:', error);
      alert('Erro ao carregar dados do produto.');
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setEditingProduct({
      id: null,
      codigo: '',
      nome: '',
      valorUnitario: '',
      valorPontos: ''
    });
  };

  const handleSaveEdit = async () => {
    try {
      await api.put(`/api/produtos/${editingProduct.id}`, {
        codigo: editingProduct.codigo,
        nome: editingProduct.nome,
        valorUnitario: editingProduct.valorUnitario,
        valorPontos: editingProduct.valorPontos
      });
      
      alert('Produto atualizado com sucesso!');
      handleCloseModal();
      buscarProdutos();
    } catch (error) {
      console.error('Erro ao atualizar produto:', error);
      alert(error.response?.data?.erro || 'Erro ao atualizar produto.');
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditingProduct(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleDelete = async (id) => {
    if (window.confirm('Tem certeza que deseja deletar este produto?')) {
      try {
        await api.delete(`/api/produtos/${id}`);
        alert('Produto deletado com sucesso!');
        buscarProdutos();
      } catch (error) {
        console.error('Erro ao deletar produto:', error);
        alert(error.response?.data?.erro || 'Erro ao deletar produto.');
      }
    }
  };

  const columns = [
    {
      dataField: 'codigo',
      text: 'Código',
      sort: true,
      sortFunc: sortFunc,
    },
    {
      dataField: 'nome',
      text: 'Produto',
      sort: true,
      style: { textAlign: 'left' },
      headerStyle: { textAlign: 'left' },
    },
    {
      dataField: 'valorPontos',
      text: 'Pontos',
      formatter: (celula, valor) => {
        return Number(valor.valorPontos).toLocaleString('pt-BR');
      },
      sort: true,
      sortFunc: sortFunc,
    },
    {
      dataField: 'acoes',
      text: 'Ações',
      formatter: (cell, row) => {
        return (
          <div>
            <button
              className="btn btn-primary btn-sm mr-2"
              onClick={() => handleEdit(row)}
            >
              <i className="fas fa-edit"></i>
            </button>
            <button
              className="btn btn-danger btn-sm"
              onClick={() => handleDelete(row.id)}
            >
              <i className="fas fa-trash"></i>
            </button>
          </div>
        );
      },
      headerStyle: { textAlign: 'center' },
      style: { textAlign: 'center' }
    }
  ];

  const defaultSorted = [
    {
      dataField: 'valorPontos',
      order: 'desc',
    },
  ];

  const options = {
    sizePerPage: 10,
    showTotal: true,
    sizePerPageList: [
      {
        text: '5',
        value: 5,
      },
      {
        text: '10',
        value: 10,
      },
      {
        text: '50',
        value: 50,
      },
      {
        text: '100',
        value: 100,
      },
      {
        text: 'Todos',
        value: data.length,
      },
    ],
    paginationTotalRenderer: (from, to, size) =>
      size > 0 ? (
        <span className="react-bootstrap-table-pagination-total px-2">
          {from}-{to} de {size} resultados
        </span>
      ) : (
        <span className="react-bootstrap-table-pagination-total px-2">
          Nenhum resultado
        </span>
      ),
  };

  return (
    <Container fluid>
      <h4 className="pt-3 pb-3 f-black">Produtos Sherwin-Williams</h4>
      <hr className="hr-loja mt-0 mb-4" />
      <div>
        <ToolkitProvider
          bootstrap4
          keyField="codigo"
          data={data}
          columns={columns}
          search
        >
          {(props) => (
            <div>
              <div className="d-flex flex-row-reverse mb-2">
                <ToSearch {...props.searchProps} />
              </div>
              <BootstrapTable
                defaultSorted={defaultSorted}
                noDataIndication={() =>
                  buscando ? (
                    <span>
                      Carregando
                      <i className="fas fa-circle-notch fa-spin ml-3"></i>
                    </span>
                  ) : (
                    <span>Sem resultados</span>
                  )
                }
                classes="table-dark text-center rounded"
                wrapperClasses="rounded"
                bordered={false}
                keyField="codigo"
                pagination={paginationFactory(options)}
                {...props.baseProps}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
      
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Editar Produto</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Código</Form.Label>
              <Form.Control
                type="text"
                name="codigo"
                value={editingProduct.codigo}
                onChange={handleInputChange}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Nome</Form.Label>
              <Form.Control
                type="text"
                name="nome"
                value={editingProduct.nome}
                onChange={handleInputChange}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Valor Unitário</Form.Label>
              <Form.Control
                type="number"
                step="0.01"
                name="valorUnitario"
                value={editingProduct.valorUnitario}
                onChange={handleInputChange}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Valor em Pontos</Form.Label>
              <Form.Control
                type="number"
                step="0.01"
                name="valorPontos"
                value={editingProduct.valorPontos}
                onChange={handleInputChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleSaveEdit}>
            Salvar Alterações
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}
