/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import api from '../../../services/API';
import './styles.css';
export default function Cursos(props) {
  const [cursos, setCursos] = useState([]);
  const [busca, setBusca] = useState('');
  const [buscando, setBuscando] = useState(false);

  async function buscarCursos(filters = {}) {
    setBuscando(true);
    try {
      const resultado = await api.post(`/api/cursos/parceiro/curso/search`, {
        where: {
          Curso: {
            ...filters,
            ativo: true,
          },
        },
      });

      if (resultado) {
        setCursos(resultado.data);
      }
    } catch (error) {}
    setBuscando(false);
  }

  function handleEnter(e) {
    if (e.key === 'Enter') {
      buscaComTexto(e);
    }
  }

  function buscaComTexto() {
    const nome = busca ? { nome: { 'Op.like': busca } } : {};
    buscarCursos(nome);
  }

  const onCurso = (id) => {
    window.open('/cursos/' + id, '_self');
  };

  const limitText = (t) => {
    if (typeof t !== 'string') return '';

    if (t.length > 50) {
      t = t.slice(0, 50);
      return `${t} ...`;
    }
    return t;
  };

  useEffect(() => {
    buscarCursos();
  }, []);

  useEffect(() => {
    document.title = 'Clube Pro Pintor - Loja';
    buscarCursos();
  }, []);

  return (
    <Container className="mb-5 ">
      <Row
        className="m-2 p-4 pt-5 mt-5"
        style={{ background: '#e5e5e5', borderRadius: '1rem' }}
      >
        <Col xs={12} md={6}>
          <h5 className="mb-3 h3">
            <strong>Sobre o Programa</strong>
          </h5>
          <span className="mb-3">
            O Programa Sherwin-Williams Pro Cursos tem como objetivo comum de
            ajudar os pequenos empreendimentos que prestam serviço de pintura a
            se tornarem empreendedores de sucesso. Ao realizarem os programas
            propostos ganham pontos que podem ser trocados por benefícios. Esse
            programa é exclusivo para prestadores de serviços de pintura.
            <br />
            <br />
            Sim, esses cursos online do Sherwin-Williams Pro estão entre essas
            vantagens! A ideia é tornar o pintor mais capacitado para
            administrar tudo que envolve seu negócio, através de conhecimentos
            fundamentais sobre administração e formalização de negócios,
            ajudando a aumentar suas vendas de maneira simples e prática. As
            soluções têm formato 100% online e com conteúdos e atividades focados
            no seu desenvolvimento.
          </span>
        </Col>
        <Col
          xs={12}
          md={6}
          className="mb-3 d-flex justify-content-center align-items-center"
        >
          <img
            style={{ width: '680px' }}
            className="d-none d-md-block "
            src={'/imagens/Pintor_Curso.png'}
            alt=""
          />
        </Col>
      </Row>

      <Row className="g-2 justify-content-evely">
        <Col xs={12}>
          <div className="mt-3 align-self-start sticky-search">
            <div className="d-flex flex-row justify-content-between ">
              <h5 className="my-4 h3">
                CURSOS EM <strong>DESTAQUE</strong>
              </h5>{' '}
              <div>
                <input
                  value={busca}
                  onChange={(e) => setBusca(e.target.value)}
                  onKeyUp={handleEnter}
                  className="input-busca"
                  placeholder="Digite o nome do curso"
                />
                <button
                  className="btn-busca mt-0"
                  type="submit"
                  onClick={buscaComTexto}
                >
                  <i className="fas fa-search"></i>
                </button>
              </div>
            </div>
            <hr className="hr-loja mt-0" />
          </div>
        </Col>
      </Row>
      <Row style={{ gap: '0.4rem', justifyContent: 'start' }}>
        {cursos.map((i) => (
          <Col
            className="box-curso"
            xs={5}
            sm={5}
            md={2}
            onClick={onCurso.bind(this, i.id)}
          >
            <img src={i.linkCapaCurso} className="capa" alt="" />

            {/* <p className="tag-curso">
              {i.preco
                ? Number(i.preco).toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })
                : 'GRATUITO'}
            </p> */}

            <div className=" pt-2">
              <h6 className="mt-1">
                <strong> {i.nome}</strong>
              </h6>
            </div>

            <div className="d-flex justify-content-start position-absolute box-buttons">
              <button className="btn-primario px-4">Acessar </button>
              {/* <button className="btn btn-dark m-2">Saiba Mais </button> */}
            </div>
          </Col>
        ))}
      </Row>

      {/* <Row>
        <Col xs={12} md={4} lg={3}>
          <div className="mt-3">
            <h5 className="mb-3">CATEGORIAS</h5>
            <hr className="hr-loja mt-0" />
          </div>
          <Row className="sticky">
            <Col>
              <ul className="list-unstyled">
                <li className="tag mb-2" onClick={gerenciarTagSaldo}>
                  {!buscaSaldo ? (
                    <i className="far fa-square mr-3"></i>
                  ) : (
                    <i className="far fa-check-square mr-3"></i>
                  )}
                  Resgates disponíveis
                </li>
                {tags.map((tag) => (
                  <li className="tag" onClick={gerenciarTags} key={tag}>
                    {tagsSelecionadas.indexOf(tag) === -1 ? (
                      <i className="far fa-square mr-3"></i>
                    ) : (
                      <i className="far fa-check-square mr-3"></i>
                    )}
                    {tag}
                  </li>
                ))}
              </ul>
              <div className="limpar-filtros">
                <button className="btn-limpo" onClick={limparFiltros}>
                  Limpar filtros
                </button>
                <i className="fas fa-trash"></i>
              </div>
            </Col>
          </Row>
        </Col>

        <Col xs={12} md={8} lg={9}>
          <div className="mt-3 align-self-start sticky-search">
            <div className="d-flex flex-row justify-content-between ">
              <h5 className="mb-3">PRÊMIOS</h5>
              <div>
                <input
                  value={busca}
                  onChange={(e) => setBusca(e.target.value)}
                  onKeyUp={handleEnter}
                  className="input-busca"
                  placeholder="O que deseja resgatar?"
                />
                <button
                  className="btn-busca mt-0"
                  type="submit"
                  onClick={buscaComTexto}
                >
                  <i className="fas fa-search"></i>
                </button>
              </div>
            </div>
            <hr className="hr-loja mt-0" />
          </div>
          {buscando && (
            <Container>
              <Row className="busca-sem-resultados">
                <Col
                  xs={1}
                  className="d-flex justify-content-center align-items-center"
                >
                  <i className="fas fa-circle-notch fa-spin"></i>
                </Col>
                <Col>
                  <span>Buscando...</span>
                </Col>
              </Row>
            </Container>
          )}
          {premios.length === 0 && !buscando && (
            <Container>
              <Row className="busca-sem-resultados">
                <Col
                  xs={1}
                  className="d-flex justify-content-center align-items-center"
                >
                  <i className="fas fa-search"></i>
                </Col>
                <Col>
                  <span>
                    Sua busca{' '}
                    {busca && (
                      <>
                        por <span className="f-blue">{busca} </span>
                      </>
                    )}
                    não encontrou resultados :(
                  </span>
                  <br></br>
                  <small>
                    Por favor, tente outra vez com termos menos específicos.
                  </small>
                </Col>
              </Row>
            </Container>
          )}
          <Row>
            {!buscando &&
              premios.map((premio) => {
                return (
                  <Col
                    key={premio.codigo}
                    id={premio.codigo}
                    name="premio"
                    xs={12}
                    md={4}
                    lg={3}
                    className="mt-5"
                  >
                    <div className="d-flex flex-column justify-content-center align-items-center text-center">
                      <Link to={`/premio/${premio.codigo}`}>
                        <img
                          alt={premio.nome}
                          className="w-100"
                          src={premio.diretorioFoto}
                        ></img>
                      </Link>

                      <span className="card-premio-nome">{premio.nome}</span>
                      <span>
                        <b>
                          {Number(premio.pontosWeb).toLocaleString('pt-BR')}{' '}
                          pontos
                        </b>
                      </span>
                      <Link to={`/premio/${premio.codigo}`}>
                        <button className="btn-resgatar mt-1">RESGATAR</button>
                      </Link>
                    </div>
                  </Col>
                );
              })}
          </Row>
          <Row>
            <Col xs={12} className="mt-5">
              <button
                className="btn-resgatar"
                onClick={(e) => window.scrollTo({ top: 0, behavior: 'smooth' })}
              >
                Voltar ao topo
              </button>
            </Col>
          </Row> 
        </Col>
      </Row> */}
    </Container>
  );
}
