import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import './styles.css';
import { Link } from 'react-router-dom';
import Footer from '../../../components/Loja/Footer';
import Header from '../../../components/Loja/Header';


export default function Home() {
  useEffect(() => {
    document.title = 'Clube Pro Pintor - Clube de Fidelidade';
  }, []);

  return (
    <>
      <Header auto />
      <div className="d-block d-md-none justify-content-center align-items-center">
        <div className="mt-5">
          <a href="https://www.acheseupintor.com.br/">
            <img
              className="full-width banner-home"
              src="imagens/banners/banner_pontos_01.jpg"
              alt="Clube Pro Pintor"
            />
          </a>
        </div>
      </div>
      <Container className="d-flex flex-column justify-content-center align-items-center">
        <div className="mt-5">
          <a href="https://www.acheseupintor.com.br/" className="d-none d-md-block">
            <img
              className="w-100 banner-home"
              src="imagens/banners/banner_pontos_01.jpg"
              alt="Clube Pro Pintor"
            />
          </a>
        </div>
        <div className="p-3 f-22">
          <hr className="hr-loja mt-5 mb-4" />
          <span className="f-blue font-weight-bold font-italic f-22 mr-md-5 ml-md-5">
            SWPRO - Canal de apoio ao pintor profissional Programa de fidelidade
          </span>
          <br></br>
          <br></br>
          <span className="d-flex text-justify font-color-secondary font-italic mr-md-5 ml-md-5">
            Sherwin-Williams Pro é um programa em que pintores encontram
            informações úteis para seu desenvolvimento pessoal e profissional,
            atualizam-se com as regulamentações de trabalho, técnicas de pintura
            e fortalecem o relacionamento com outros pintores, contribuindo com
            opiniões e sugestões. E, no Clube Pro Pintor, suas compras valem
            pontos! Faça parte do clube de fidelidade e conquiste prêmios
            incríveis.
          </span>
          <div className="mt-5 mb-5 d-flex w-100 justify-content-center align-items-center">
            <a href="cadastrar" className="btn-cadastrar">
              Cadastre-se aqui
            </a>
          </div>
          <div className="mt-4 mb-4 d-block d-md-flex flex-row justify-content-center align-items-center">
            <span className="font-color-secondary font-italic">
              Conheça nossas redes sociais:
            </span>
            <div className="d-flex align-items-center mt-3 mb-3 mt-md-0 mb-md-0 ml-md-3 mr-md-3">
              <i className="fab fa-instagram-square f-30 f-blue mr-2"></i>
              <span className="font-italic">
                <a
                  className="link-clean font-color-secondary"
                  href="http://www.instagram.com/swpropintor/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  @swpropintor
                </a>
              </span>
            </div>
            <div className="d-flex align-items-center">
              <i className="fab fa-facebook-square f-30 f-blue mr-2"></i>
              <span className="font-italic">
                <a
                  className="link-clean font-color-secondary"
                  href="https://www.facebook.com/swpropintor"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  /swpropintor
                </a>
              </span>
            </div>
          </div>
          <hr className="hr-loja mt-4 d-flex mb-3" />
        </div>
      </Container>

      <Footer />
    </>
  );
}
