import './styles.css';

import { Col, Container, Row } from 'react-bootstrap';

import React from 'react';

export default function Footer(props) {
  const ano = new Date();

  return (
    <footer className={props.fixed ? 'footer-fixed' : 'footer pt-2 pb-2'}>
      <Container className="d-flex justify-content-center align-items-center text-center h-100">
        <Row className="d-flex justify-content-center align-items-center text-center w-100">
          <Col xs={6} md={4} className="pb-2 pt-2">
            <span>
              <i className="far fa-registered"></i>
              {`Todos os direitos reservados - ${ano.getFullYear()} - Smart Fidelidade Clube Pro Pintor`}
            </span>
          </Col>
          <Col xs={6} md={4} className="pb-2 pt-2">
            <span>
              <i className="fas fa-mobile"></i> (11) 99792-9110
            </span>
            <br />
            <span>
              <i className="fas fa-envelope"></i> sac.clubepropintor@geniale.net
            </span>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center align-items-center text-center w-100">
          <Col xs={6} md={4} className="pb-2 pt-2">
            <a href="politica-privacidade" className="link-clean f-white">
              Política de Privacidade Smart
            </a>
          </Col>
          <Col xs={6} md={4} className="pb-2 pt-2">
            <a href="regulamento" className="link-clean f-white">
              Regulamento
            </a>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}
